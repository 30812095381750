import { Moment } from 'moment';
import { LocationChangeAction } from 'connected-react-router';
import {
  Contact,
  Counterparty, CounterpartyAddress, CounterpartyAddressContactInfo, Phone,
} from '../contact/types';
import { Cargo } from '../cargo/types';

export const SET_CALCULATION_DONE = 'SET_CALCULATION_DONE';
export const SET_SENDER_CONTACT = 'SET_SENDER_CONTACT';
export const SET_RECEIVER_CONTACT = 'SET_RECEIVER_CONTACT';
export const RESET_CALCULATION = 'RESET_CALCULATION';
export const SET_CALCULATION_CARGO = 'SET_CALCULATION_CARGO';
export const SET_DATE_FROM = 'SET_DATE_FROM';
export const SET_CALCULATION_RUNNING = 'SET_CALCULATION_RUNNING';
export const SET_CALCULATION_TASK_ID = 'SET_CALCULATION_TASK_ID';
export const SET_CALCULATION_RESULTS = 'SET_CALCULATION_RESULTS';
export const ADD_CALCULATION_RESULTS = 'ADD_CALCULATION_RESULTS';
export const SET_CALCULATION_POLLING_TIMER = 'SET_CALCULATION_POLLING_TIMER';
export const SET_CALCULATION_PRE_STATE = 'SET_CALCULATION_PRE_STATE';
export const SET_SENDER_ADDRESS = 'SET_SENDER_ADDRESS';
export const SET_RECEIVER_ADDRESS = 'SET_RECEIVER_ADDRESS';
export const SET_CALCULATION_SENDER = 'SET_CALCULATION_SENDER';
export const SET_CALCULATION_RECEIVER = 'SET_CALCULATION_RECEIVER';
export const SET_CALCULATION_SENDER_CAST = 'SET_CALCULATION_SENDER_CAST';
export const SET_CALCULATION_RECEIVER_CAST = 'SET_CALCULATION_RECEIVER_CAST';
export const UPDATE_CALCULATION_SENDER_CAST = 'UPDATE_CALCULATION_SENDER_CAST';
export const UPDATE_CALCULATION_RECEIVER_CAST = 'UPDATE_CALCULATION_RECEIVER_CAST';
export const CALC_AVAILABLE_FILTERS = 'CALC_AVAILABLE_FILTERS';
export const CHECK_CURRENT_FILTERS = 'CHECK_CURRENT_FILTERS';
export const UPDATE_CURRENT_DELIVERY_SERVICE_FILTER = 'UPDATE_CURRENT_DELIVERY_SERVICE_FILTER';
export const UPDATE_CURRENT_DELIVERY_WAY_FILTER = 'UPDATE_CURRENT_DELIVERY_WAY_FILTER';
export const SET_CURRENT_ORDERING = 'SET_CURRENT_ORDERING';
export const SWITCH_CURRENT_FILTER = 'SWITCH_CURRENT_FILTER';
export const UPDATE_SERVICES_FILTER = 'UPDATE_SERVICES_FILTER';
export const SET_AMO_ID = 'SET_AMO_ID';
export const SET_SHARE_EMAIL = 'SET_SHARE_EMAIL';
export const SET_CURRENT_RATE = 'SET_CURRENT_RATE';

/* Recalculating */
export const SET_RECALCULATION_RESULTS = 'SET_RECALCULATION_RESULTS';
export const SET_RECALCULATION_DONE = 'SET_RECALCULATION_DONE';
export const SET_RECALCULATION_RUNNING = 'SET_RECALCULATION_RUNNING';
export const SET_RECALCULATION_TASK_ID = 'SET_RECALCULATION_TASK_ID';
export const ADD_RECALCULATION_RESULTS = 'ADD_RECALCULATION_RESULTS';
export const RESET_BASE_CALCULATION = 'RESET_BASE_CALCULATION';

export enum AdditionalServiceStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NEED_CONFIRM = 'NEED_CONFIRM'
}

export enum RateAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  RETURN = 'RETURN',
  BY_HAND = 'BY_HAND',
  PICKUP_IN_60_MIN = 'PICKUP_IN_60_MIN',
  PRIORITY_CONTROL = 'PRIORITY_CONTROL'
}

export type AdditionalService = {
  code: string;
  status: AdditionalServiceStatus;
  amount: number;
};

export enum RateSpeedChoices {
  DEFAULT = 'DEFAULT',
  DAILY = 'DAILY',
  BY_TIME = 'BY_TIME'
}

export enum RateTypeChoice {
  D2D = 'd2d',
  D2W = 'd2w',
  W2D = 'w2d',
  W2W = 'w2w'
}

export enum CalculationCargoOfferTypes {
  ORIGINAL = "ORIGINAL",
  OPTIMAL = "OPTIMAL",
  FASTEST = "FASTEST",
  CHEAPEST = "CHEAPEST"
}

export type CalculationCargoOfferType =
  | CalculationCargoOfferTypes.ORIGINAL
  | CalculationCargoOfferTypes.OPTIMAL
  | CalculationCargoOfferTypes.FASTEST
  | CalculationCargoOfferTypes.CHEAPEST;

export interface SingleRate {
  rateResultId: string,
  deliveryService: string,
  code: string,
  name: string,
  price: string,
  minDays: number,
  maxDays: number,
  pickupExtra: string,
  deliveryExtra: string,
  listPrice: number,
  additionalServices: AdditionalService[],
  multiSaving: boolean,
  originalDeliveryService?: string,
  rateSpeed: RateSpeedChoices,
  deliveryTime?: string,
  rateType: RateTypeChoice,
  cashbackPercent?: number,
  description?: string,
}

export interface CalculationSide {
  counterparty: Counterparty,
  address: CounterpartyAddress,
  contact: CounterpartyAddressContactInfo
}

export interface CalculationSideCast {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  office: string,
  longitude: number,
  latitude: number,
  comment: string,
  contactName?: string,
  companyName?: string,
  email?: string,
  phones?: Array<Phone>,
  inn?: string,
  addressCastId?: string,
  contactInfoId?: string,
  contactCastId?: string,
  intercomNumber?: string,
  intercomCode?: string,
  preCallPeriod?: number,
  workingFrom?: string,
  workingTo?: string,
  lunchFrom?: string,
  lunchTo?: string,
  needPass?: boolean,
  needIdCard?: boolean,
}

export type CalculationFilter = {
  deliveryServices: Array<string>,
  services: string[],
  deliveryWay: string[]
};

export type CurrentCalculationFilter = {
  deliveryServices: {
    touched: boolean,
    open: boolean,
    values: Array<string>
  },
  services: {
    touched: boolean,
    open: boolean,
    values: Array<string>
  },
  deliveryWay: {
    touched: boolean,
    open: boolean,
    values: Array<string>
  }
};

export enum FastCalcInitialState {
  SENDER_ADDRESS,
  RECEIVER_ADDRESS,
  CARGO
}

export type StartFastCalcResponse = {
  initialState: FastCalcInitialState,
  errors?: anyObject,
  initialData?: any
};

export interface SetCalculationDone {
  type: typeof SET_CALCULATION_DONE,
  done: boolean,
}

export interface SetSenderContact {
  type: typeof SET_SENDER_CONTACT,
  contact: Contact | null,
}

export interface SetReceiverContact {
  type: typeof SET_RECEIVER_CONTACT,
  contact: Contact | null,
}

export interface ResetCalculation {
  type: typeof RESET_CALCULATION,
}

export interface SetCalculationCargo {
  type: typeof SET_CALCULATION_CARGO,
  cargo: Cargo | null,
}

export interface SetDateFrom {
  type: typeof SET_DATE_FROM,
  dateFrom: Moment | null,
}

export interface SetCalculationRunning {
  type: typeof SET_CALCULATION_RUNNING,
  running: boolean,
}

export interface SetCalculationTaskId {
  type: typeof SET_CALCULATION_TASK_ID,
  taskId: string
}

export interface SetCalculationResults {
  type: typeof SET_CALCULATION_RESULTS,
  results: SingleRate[]
}

export interface SddCalculationResults {
  type: typeof ADD_CALCULATION_RESULTS,
  results: SingleRate[],
}

export interface SetCalculationPollingTimer {
  type: typeof SET_CALCULATION_POLLING_TIMER,
  pollingTimer: any
}

export interface SetCalculationPreState {
  type: typeof SET_CALCULATION_PRE_STATE,
  preState: 'sender' | 'receiver' | 'cargo' | 'new_sender' | 'new_receiver',
}

export interface SetSenderAddress {
  type: typeof SET_SENDER_ADDRESS,
  address: CounterpartyAddress | null,
}

export interface SetReceiverAddress {
  type: typeof SET_RECEIVER_ADDRESS,
  address: CounterpartyAddress | null,
}

export interface SetCalculationSender {
  type: typeof SET_CALCULATION_SENDER,
  sender: CalculationSide | null,
}

export interface SetCalculationReceiver {
  type: typeof SET_CALCULATION_RECEIVER,
  receiver: CalculationSide | null,
}

export interface SetCalculationSenderCast {
  type: typeof SET_CALCULATION_SENDER_CAST,
  cast: CalculationSideCast | null,
}

export interface SetCalculationReceiverCast {
  type: typeof SET_CALCULATION_RECEIVER_CAST,
  cast: CalculationSideCast | null,
}

export interface UpdateCalculationSenderCast {
  type: typeof UPDATE_CALCULATION_SENDER_CAST,
  cast: CalculationSideCast,
}

export interface UpdateCalculationReceiverCast {
  type: typeof UPDATE_CALCULATION_RECEIVER_CAST,
  cast: CalculationSideCast,
}

export interface CalcAvailableFilters {
  type: typeof CALC_AVAILABLE_FILTERS,
}

export interface CheckCurrentFilters {
  type: typeof CHECK_CURRENT_FILTERS,
}

export interface UpdateCurrentDeliveryServiceFilter {
  type: typeof UPDATE_CURRENT_DELIVERY_SERVICE_FILTER,
  deliveryServices: string[]
}

export interface UpdateCurrentDeliveryWayFilter {
  type: typeof UPDATE_CURRENT_DELIVERY_WAY_FILTER,
  deliveryWay: string[]
}
export interface SetCurrentOrdering {
  type: typeof SET_CURRENT_ORDERING,
  ordering: 'price' | 'minDays',
}

export interface SwitchCurrentFilter {
  type: typeof SWITCH_CURRENT_FILTER,
  filter: 'deliveryServices' | 'services',
}

export interface UpdateServicesFilter {
  type: typeof UPDATE_SERVICES_FILTER,
  services: string[],
  initial?: boolean,
}

export interface SetAmoId {
  type: typeof SET_AMO_ID,
  amoId: null | number | string,
}

export interface SetShareEmail {
  type: typeof SET_SHARE_EMAIL,
  shareEmail: null | string,
}

export interface SetCurrentRate {
  type: typeof SET_CURRENT_RATE,
  payload: string
}

export interface SetRecalculationResults {
  type: typeof SET_RECALCULATION_RESULTS;
  results: any
}

export interface SetRecalculationDone {
  type: typeof SET_RECALCULATION_DONE;
  done: boolean
}

export interface SetRecalculationRunning {
  type: typeof SET_RECALCULATION_RUNNING;
  running: boolean
}

export interface SetRecalculationTaskId {
  type: typeof SET_RECALCULATION_TASK_ID;
  taskId: any
}

export interface AddRecalculationResults {
  type: typeof ADD_RECALCULATION_RESULTS;
  results: any
}

export interface ResetBaseCalculation {
  type: typeof RESET_BASE_CALCULATION;
}

export type CalculationActions = SetCalculationDone | SetSenderContact | SetReceiverContact | ResetCalculation |
SetCalculationCargo | SetDateFrom | SetCalculationRunning | SetCalculationTaskId | SetCalculationResults |
SddCalculationResults | SetCalculationPollingTimer | SetCalculationPreState | SetSenderAddress | SetReceiverAddress |
SetCalculationSender | SetCalculationReceiver | SetCalculationSenderCast | SetCalculationReceiverCast |
UpdateCalculationSenderCast | UpdateCalculationReceiverCast | CalcAvailableFilters | CheckCurrentFilters |
UpdateCurrentDeliveryServiceFilter | UpdateCurrentDeliveryWayFilter | SetCurrentOrdering | SwitchCurrentFilter | UpdateServicesFilter | SetAmoId | SetShareEmail |
LocationChangeAction | SetCurrentRate | SetRecalculationResults | SetRecalculationDone | SetRecalculationRunning | SetRecalculationTaskId | AddRecalculationResults | ResetBaseCalculation;
